import React from 'react';
import cls from 'classnames';

import SvgIcon from 'src/explore/elements/SvgIcon';

import styles from './index.module.sass';

interface RatingStarsProps {
  className?: string;
  max?: number;
  value?: number;
}

export const RatingStars = ({ className, max = 5, value = 0 }: RatingStarsProps ) => (
  <>
    {[ ...Array( max ) ].map(( _, number ) => {
      let icon = 'star-empty';
      if ( value >= number + 1 ) {
        icon = 'star-full';
      } else if ( value > number ) {
        icon = 'star-half';
      }

      return (
        <SvgIcon icon={icon} key={`star-${number}`} svgClasses={cls( styles.star, className )} />
      );
    })}
  </>
);
