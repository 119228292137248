import React from 'react';
import cls from 'classnames';

import styles from './index.module.sass';

export const StatusOverlay = ({ className, text }) => (
  <span
    className={cls(
      styles.overlay,
      'position-absolute d-flex justify-content-center align-items-center w-100 p-2 text-white font-weight-bold',
      className,
    )}
  >
    <span className={`${styles.text} text-center`}>{text}</span>
  </span>
);
